.eJhFQa{
  background-color: #fff;
}

.grid-container{ 
  display: grid;
  grid-template-columns: auto auto ;
  border-radius: 20px;
 padding: 10px;
  grid-gap: 1rem;
  width: 100%;
  background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(110,0,255,1) 35%, rgba(0,212,255,1) 100%);
}



.grid-container-organizers{ 
  display: grid;
  grid-template-columns: auto auto auto ;
  border-radius: 20px;
 padding: 10px;
  grid-gap: 1rem;
  width: 100%;
  background: #fff
}



.grid-item {
  /* border: 1px solid #180d5b; */
  color: #180d5b;
  /* border-radius: 20px; */
  padding: 20px;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  font-size: small;
  /* justify-content: center; */

}


.image{
  float: left;
    width:  200px;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
}

.card-text-resp{
  font-size: 1.2em;
  font-weight: bold;
  color: black;
}

/* ANIMATION */

.transition2 {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.4s ease-in-out, transform 0.6s ease-in-out;
}

.grid-item {
  animation: floatUp 1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes floatUp {
  from {
      transform: translateY(20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}


@media only screen and (max-width :350px){
  .card-title{
    text-align: justify;
    font-size: 1em;
  }
}



@media only screen and (max-width :900px){
  .grid-container{
    display: flex;
    flex-direction: column;
  }
  .grid-container-organizers{
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width:1000px){
  .container{
    margin-top: -120px;
 }
}

