/* src/styles.css */

.conference-program {
    text-align: center;
    padding: 20px;
  }

  .btnDiv{
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .toggle-button {
    background-color: #321253;
    color: #FFF;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    width: 200px;
    transition: background-color 0.3s ease-in-out;
    
  }

  .toggle-button:hover {
    color: #fff;
    background-color: #927daf;
    font-weight: bold;
    border-color: #fff;
  }

  .dl-button {
    background-color: #321253;
    color: #FFF;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 200px;
        
    }
      
    .hrefClick{
      color: #FFF;
    }
  
  .event{
    border-top: 1px solid #ccc; /* Adds a 1px solid line as a separator */
    margin: 10px 0;
  }

  .eventAfterNoon{
    border-top: 4px solid #6e00ff; /* Adds a 1px solid line as a separator */
    margin: 20px 0;
  }

.theme{
    color:#6e00ff
  }

  
  

  .program-content {
    display: none;
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .program-content.active {
    display: block;
    opacity: 1;

  }


  .button-blue {
    background-color: #3498db;
    color: #fff;
  }
  
  .button-red {
    background-color: #e74c3c;
    color: #fff;
  }
  
/* ANIMATIONS */
/* Add a transition for the 'program-content' class */
.program-content {
  max-height: 0;
  
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  
  animation: slideIn 0.5s ease-in;
}

/* Define keyframes for a fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the fade-in animation to the 'program-content.active' class */
.program-content.active {
  
    max-height: 100%; /* You can also use a specific value in pixels. */
  animation: fadeIn 0.5s ease-in;
}

/* MEDIA QUERIES */

 @media screen and (max-width: 900px) {
  .conference-program{
    margin-top: 10px;
  }
  .toggle-button{
    
    margin-right: 0px;
  }
 }
  @media screen and (max-width: 750px) {
    .conference-program{
      margin-top: 0;
    }
    .btnDiv{
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
    }

    .dl-button{
      margin-top: 10px;
    }


  }