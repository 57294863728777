// Mobile first css
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

body {
    height: 100vh;
    margin: 0;
    font-family: 'Poppins', arial;
}


.btn{
    width: 300px;
    border-radius: 10px;
    border-color: #6700EE;
    padding: 5px;
    font-size: 1.2em;
  }
  
.btn:hover {
    color: #fff;
    background-color: #150e20;
    font-weight: bold;
    border-color: #fff;
  }

.content{
    
    background-color: #6e00ff;
}

.content a {
    color: #fff;
    
    
  }
  
  a:hover{
    color: #c0a375;
    border-bottom: 1px solid #252525;
    cursor: pointer;
}
ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
}

img{
    width: 100%;
}

.nav-container{
    background-color: #6700EE;

    a{
        color:white;
    }
}

header {
    display: flex;
    justify-content: space-between; 
    padding: 2em;
    
    .logo{
        color: #00F7FF;
        font-weight: bold;
    }

   

    ul{
        display: flex;

        a{
            display: block;
            padding: 0 1em;
        }
    }
    
    
}
.sponsers{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
// Social headers

.social-header , .hero-design{
    display: none;
}

// HERO section 

.brain-icon {
    font-size: 32px; /* Adjust the font size as needed */
    color: #111111; /* Set the desired color */
  }

.hero {
    background: #6E00FF;
    color: white;
    display: grid;
    text-align: center;
    padding: 4em;
    margin-top: -180px;
    
}

.meet{
    display: flex;
    margin: 0 auto;
    width:  200px;
    font-weight: bold;
    color: #FF64CB;

    span{
        margin-top: 1em;
        margin-right: .5em;
    }
}

.scroll {
    width: 30px;
    margin-top: 2em;
}

section {
    padding: 4em 2em;
    text-align: center;
}

.featured {
    position: relative;
    text-align: center;
}

.featured::before {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    
}

.subtitle {
    text-transform: uppercase;
    font-weight: bold;
    color:  #6e00ff;
    letter-spacing: .2em;
    font-size: 1.5em;
}
.organisers {
    // font-weight: bold;
    color:  #fff;
    letter-spacing: .1em;
    font-size: 1.4em;
    padding-bottom: 2px;
    text-align: justify;
}

.featured-title{
    color: black;
    font-weight: bold;
    font-size: 1.3em;
    margin-top: -.4em;
    display: block;
}

.featured-desc{
    color: #252525;
    margin-bottom: 3em;
    font-size: .9em;
    line-height: 1.8em;
    font-weight: 500;
    text-align: justify;
}

.skills{
    background: #6E00FF;
    .image-keynote{
        width: 200px;
        border-radius: 10px;
        margin-top: 95px  ;
    }
    .image-keynote-boss{
        width: 250px;
        border-radius: 10px;
        margin-top: 95px  ;
    }
}
.skills h1{
    color: #fff;
    padding-bottom: 20px;
}


.skills-container ul li {

    background: white;
    padding: 2em;
    border-radius: 1em;
    margin-bottom: 1em;

    .icon-container {
        height: 100px;
        display: grid;
        place-content: center;
        margin: 0 auto;

        &.one {
            width: 50px;
        }
        &.two {
            width: 100px;
        }
        &.three {
            width: 60px;
        }
        &.four {
            width: 60px;
        }
    }

    .skill-title {
        font-weight: bold;
    }
    .skill-title-keynote{
        font-weight: bold;
        font-size: 1.3em;
        margin-top: 98px;
    }
    .featured-desc {
        margin-bottom: 2em;
    }
}



.portfolio {
    background: #F7F7F7;
}


.portfolio-container a img {
    border-radius: 1em;
    margin-bottom: 2em;
}

mark {
  -webkit-animation: 1.5s highlight 1.5s 1 normal forwards;
          animation: 1.5s highlight 1.5s 1 normal forwards;
  background-color: none;
  background: linear-gradient(90deg, #7efff5 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
}

@-webkit-keyframes highlight {
  to {
    background-position: 0 0;
  }
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}

// anumation
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.square-anim {
    animation: fadeIn 1s ease-in-out;
    animation-fill-mode: both;
}

@keyframes floatUp {
    0% {
        transform: translateY(20px); /* Start the image lower */
        opacity: 0; /* Start invisible */
    }
    100% {
        transform: translateY(0); /* End with the image floating up */
        opacity: 1; /* Fully visible */
    }
}

.transition2 {
    animation: floatUp 1s ease-in-out;
    animation-fill-mode: both;
    transition: opacity 0.4s ease-in-out;
}

@media only screen and (max-width: 900px) {
    
    .hero{
       margin-top: 0px;
       .content{
        background-color: #6e00ff;
       
       }
    }
    .sponsers{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (min-width: 800px) {

    .featured, .portfolio {
        text-align: left;
    }
    .featured, .portfolio-container {
        display: grid;
        grid-template-columns: 40% auto;
    }

    .left, .portfolio-left {
        display: grid;
        place-content: center;
    }

    .right {
        margin-left: 2em;
        margin-top: 1em;
        border-radius: 20px;
    }

    .skills {
        margin-top: -5em;
        margin-bottom: -7em;
        padding-top: 7em;
    }

    .skills-container ul {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 1em;
    }

    .portfolio {
        padding-top: 7em;
    }
    .portfolio-container img {
        margin-left: 2em;
    }

}

@media only screen and (min-width: 1050px) {
    .hero {
        height: 90vh;
    
    }
    .featured{
        margin-top: 5em;
        padding-top: 7em;
    }

    .nav-container {
        display: grid;
        grid-template-columns: 66% auto;
        background: unset;

        header {
            background:  #6700EE;
        }
    }
    header {
        padding: 2em 2em 2em 4em;
    }
    .social-header {
        padding: 2em 4em 2em 0;
    }
    section {
        padding: 4em;
    }

    .social-header {
        display: block;

        ul {
            display: flex;
            justify-content: space-between;
            width: 7em;
            float: right;

            img {
                width: 20px;
            }
        }
    }

    .hero {
        display: grid;
        grid-template-columns: 66% auto;
        background: unset;
        padding: 0;


        .content {
            background: #6E00FF;
            padding: 6em 8em 6em 4em;
            text-align: left;

            h1 {
                font-size: 3em;
                line-height: 1.2em;
            }
        }
    }
    .meet {
        margin: unset;
    }
    .hero-design {
        display: unset;
        margin-left: -50%;
        margin-top: 15%;
        width: 100%;
    }

    .portfolio img {
        float: right;
        max-width: 500px;
    }
}

@media only screen and (min-width: 1250px) {

   
    header {
        padding: 2em 2em 2em 10em;
    }
    .featured{
        margin-top: 5em;
        padding-top: 7em;
    }
    .social-header {
        padding: 2em 10em 2em 0;
    }
    section {
        padding: 10em 10em 4em 10em;
    }

    .hero .content {
        padding: 6em 8em 6em 10em;
    }
}
@media only screen and (max-width: 1700px) and (min-width: 1550px)  {
    .featured{
        margin-top: 20em;
        padding-top: 7em;
    }
    .organisers{
        font-size: 1em;
    }
}

@media only screen and (max-width: 1550px) and (min-width: 1350px)  {
    .organisers{
        margin-right: 23px;
    }
}
@media only screen and (min-width: 1550px) {
    
    header {
        padding: 2em 2em 2em 20em;
    }
    .social-header {
        padding: 2em 20em 2em 0;
    }
    section {
        padding: 6em 20em 4em 20em;
    }
    .hero .content {
        padding: 9em 20em 6em 20em;
    }

}  

.circle {
    animation: circleAnim 1s infinite alternate-reverse;
}

@keyframes circleAnim {
    from {
        transform: translate(262px, 798px);
        z-index: 5;
    }
    to {
        transform: translate(262px, 830px);
    }
}
